<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                华泰证券上海普陀区江宁路营业部携手非凸科技，共话科技赋能投资新趋势
              </p>
              <div class="fst-italic mb-2">2024年4月28日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >华泰证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/79/01-活动现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    4月16日，华泰证券上海普陀区江宁路证券营业部与非凸科技联合举办了“科技赋能投资”系列主题活动，与行业人士共同探讨如何通过科技创新提升交易效率与用户体验，进而为投资者提供更高效、更智能的金融服务。
                  </p>
                  <p>
                    金融为本、科技为器，从金融的底层逻辑出发，通过科技赋能业务发展是华泰证券多年来转型超越的重要动力。华泰证券持续构建平台化、一体化、生态化的科技赋能体系，探索创新技术应用，深化数字化转型，筑牢金融科技领先优势，持续提升金融服务质效。
                  </p>
                  <p>
                    江宁路营业部总经理陈晓雪女士发言，介绍了华泰证券全业务链业务、金融科技对业务的赋能以及总分联动的协同机制，并介绍一系列领先的金融科技产品：行业领先的极速交易柜台、综合金融交易平台MATIC、极速行情Insight、自研算法平台，以及“行知”机构客户服务平台等机构业务数字服务等。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/79/02-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    目前非凸算法已成功上线华泰证券MATIC平台，双方携手共创智能交易算法新生态。活动上，非凸科技针对智能算法以及合作模式进行了详细介绍。同时分享了新形势下的技术创新应用成果，探讨了风控智能模块的建设与优化。非凸科技追求极致的稳定与高效，以及专业的交易工具与服务，全面助力行业机构交易稳健发展。
                  </p>
                  <p>
                    华泰证券融资融券部赵轶黎女士分享了量化私募两融交易思路，并表示华泰证券两融部为交易型客户构建专业化的客户服务体系，从交易系统、交易资源、营销支持等方面积极为客户搭建六位一体的客户服务体系，提供更灵活、更高效、更便捷的交易体验。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/79/03-华泰.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    华泰证券金融产品部私募业务负责人闫克锋先生通过典型案例介绍了私募产品代销和配置业务，充分介绍了华泰证券私募产品白名单准入机制。
                  </p>
                  <p>
                    交流环节，管理人与资方分享了对当前市场环境下产品筛选的独特见解，并探讨了如何在准入制度下拓展更多的联动与合作机会，以及未来金融科技在投资交易领域的新挑战与发展方向。
                  </p>
                  <p>
                    未来，非凸科技将立足金融信息技术自主创新，与华泰证券等伙伴持续深化合作，积极参与算法生态建设，为金融机构提供真正满足数智化转型需求的应用产品和解决方案，以技术创新推动行业发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News79",
};
</script>

<style></style>
